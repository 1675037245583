.modal-video{
    display: flex;
    flex-direction: column;
    position: fixed;
    width: 100%;
    height: 100%;
    padding-top: 10vh;
    z-index: 13;
    cursor: pointer;
    background-color: var(--bg-color-secondary);
    
    .video-frame{
        display: flex;
        justify-content: center;
        width: 100%;
        height: 100%;
        iframe{
            width: 90%;
            height: 80%;
            animation: fade 0.6s ease-in;
        }
    }
}

@keyframes fade {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}
