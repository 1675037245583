.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    z-index: 1000;
    width: 100vw;
    pointer-events: none;
    animation: move-down 1s ease-in-out forwards;

    .mobile {
        display: none;
    }

    div {
        padding: 30px 62px;
        pointer-events: visible;
        img{
            cursor: pointer;
        }
    }
}

svg {
    cursor: pointer;
}

.menu {
    background-color: var(--bg-color-primary);
    width: 100vw;
    height: 100vh;
    z-index: 99;
    position: fixed;
    display: flex;
    align-items: left;
    flex-direction: column;
    transform: translateY(-100vh);
    transition: 1150ms ease-in-out;
    gap: 52px;

    .menu-header {
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 103px;

        div {
            padding: 40px 62px;
        }
    }

    .menu-items {
        display: flex;
        justify-content: center;
        align-items: left;
        padding-left: 62px;
        color: var(--color-text-third);
        flex-direction: column;
        gap: 10px;

        p {
            font-size: 100px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            color: var(--color-text-third);
            cursor: pointer;
            text-decoration: none;
        }

        p:hover {
            color: white;
            transition: 0.5s;
        }
    }

    .menu-footer {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        a{
            text-decoration: none;
        }

        a:visited{
            text-decoration: none;
        }

        p {
            padding: 40px 62px;
            color: white;
            font-size: 24px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
        }
    }
}

.menu.active {
    transform: translateY(0);
}

@keyframes move-down {
    from {
        transform: translateY(-20vh);
    }

    to {
        transform: translateY(0vh);
    }
}

@media (min-width: 2000px) {
    .header {
        div {
            img {
                width: 250px;
            }

            svg {
                width: 35px;
                height: 35px;
            }
        }
    }
}

@media (min-height: 2050px) {
    .header {
        div {
            padding: 60px 82px;

            img {
                width: 550px;
            }

            svg {
                width: 70px;
                height: 70px;
            }
        }
    }

    .menu {

        .menu-header {
            div {
                padding: 60px 82px;
            }
        }

        .menu-items {
            margin-top: 100px;
            padding-left: 82px;
            gap: 50px;

            p {
                font-size: 200px;
            }
        }

        .menu-footer {
            p {
                padding: 60px 82px;
                font-size: 80px;
            }
        }
    }
}

@media (max-height: 1050px) {
    .menu {
        .menu-items {
            margin-top: 10px;
            gap: 10px;

            p {
                font-size: 100px;
            }
        }

        .menu-footer {
            p {
                font-size: 24px;
            }
        }
    }
}

@media (max-height: 900px) {
    .menu {
        .menu-items {
            p {
                font-size: 80px;
            }
        }
    }
}

@media (max-height: 790px) {
    .menu {
        .menu-items {
            margin-top: -20px;
            gap: 15px;

            p {
                font-size: 72px;
            }
        }

        .menu-footer {
            margin-top: 10px;

            p {
                font-size: 32px;
            }
        }
    }
}

@media (max-height: 720px) {
    .menu {
        div {
            svg {
                width: 20px;
                height: 20px;
            }
        }

        .menu-items {
            margin-top: -30px;
            gap: 10px;

            p {
                font-size: 54px;
            }
        }

        .menu-footer {
            p {
                margin-top: -40px;
                font-size: 24px;
            }
        }
    }
}

@media (max-width: 1000px) {
    .header {
        div {
            img {
                width: 250px;
                height: 40px;
            }

            svg {
                width: 30px;
                height: 30px;
            }

            padding: 32px;

            .mobile {
                width: 100%;
                display: block;
            }

            .pc {
                display: none;
            }
        }
    }

    .menu {
        .menu-header {
            div {
                padding: 32px;
            }
        }

        div {
            .mobile {
                width: 100%;
                height: 100%;
                display: block;
            }

            .pc {
                display: none;
            }

            svg {
                width: 20px;
                height: 20px;
            }
        }

        .menu-items {
            width: 100%;
            height: 100%;
            justify-content: center;
            padding-left: 28px;

            p {
                font-size: 80px;
            }
        }

        .menu-footer {
            display: flex;
            align-items: start;
            justify-content: end;
            flex-direction: column;
            padding-left: 28px;
            margin-bottom: 100px;
            height: auto;
            gap: 30px;

            p {
                padding: 0px 0px;
                font-size: 24px;
            }
        }
    }
}

@media(max-width: 768px){
    .header {
        div{
            img{
                width: 150px;
                height: 30px;
            }
            svg{
                width: 20px;
                height: 20px;
            }
        }
    }
}

@media (max-width: 550px) {
    .menu {
        .menu-items {
            p {
                font-size: 40px;
            }
        }

        .menu-footer {
            gap: 50px;

            p {
                font-size: 16px;
            }
        }
    }
}