.brandlab {
    width: 100vw;
    height: 100vh;
    display: flex;
    background-color: var(--bg-color-primary);
    justify-content: center;
    position: relative;
    top: 0;
    z-index: 9;
    align-items: center;
    padding-top: 200px;

    .brandlab-circle {
        display: none;
        background-color: var(--bg-color-four);
        scale: 0.05;
        border-radius: 100%;
        width: 1850px;
        height: 1850px;
        justify-content: center;
        align-items: center;
        position: absolute;
        margin-top: -30vh;
        transition: transform ease-in-out;
    }

    .front {
        width: 100%;
        height: 100%;
        position: absolute;
        display: flex;
        z-index: 50;
        justify-content: center;
        align-items: center;
        margin-top: -23vh;

        .texts {
            display: flex;
            justify-content: left;
            align-items: left;
            flex-direction: column;
            width: 100%;
            z-index: 50;
            position: absolute;
            margin-left: 8vw;
            margin-top: 170px;
            gap: 35px;
            color: white;
            min-height: 548px;

            h2 {
                opacity: 0;
                line-height: normal;

                &.show {
                    animation: move-title-brand 1.5s ease-in-out forwards;
                }
            }

            h3 {
                font-family: var(--font-secondary);
                font-weight: 700;
                font-size: 20px;
                line-height: 40px;
                max-width: 709px;
                opacity: 0;

                &.show {
                    opacity: 1;
                    animation: move-brand-paragraph 1.2s ease-out;
                }
            }

            p {
                margin-top: -44px;
                max-width: 811px;
                margin-right: 50px;
                opacity: 0;

                &.show {
                    opacity: 1;
                    animation: move-brand-paragraph 1.2s ease-out;
                }
            }

            .brands {
                display: flex;
                flex-wrap: wrap;
                max-width: 54vw;
                gap: 20px;
                row-gap: 40px;
                opacity: 0;

                &.show {
                    opacity: 1;
                    transition: opacity 1s ease-in-out;
                }

                .brand {
                    display: none;
                    justify-content: left;
                    align-items: center;
                    max-height: 60px;
                    animation: move-sub 1.3s ease-out;

                    &.show {
                        display: flex;
                    }

                    img {
                        max-width: 150px;
                        object-fit: cover;
                    }
                }
            }
        }

        .brandlab-video {
            width: 886px;
            height: 886px;
            position: absolute;
            margin-left: 51vw;
            z-index: 3;
            margin-top: 100px;

            .video-brandlab {
                width: 100%;
                height: 100%;
                border-radius: 100%;
                z-index: 0;
                animation: move-paragraph 1s ease-in-out;
                position: relative;
                overflow: hidden;

                img{
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    z-index: 0;
                    object-fit: cover;
                    object-position: center;
                }

                video{
                    width: 100%;
                    height: 100%;
                    border-radius: 100%;
                    object-fit: cover;
                    object-position: center;
                    position: absolute;
                    z-index: 1;
                }
            }
        }
    }
}

#brandlab-navigation {
    position: absolute;
    width: 100px;
    height: 100px;
    margin-top: 13vh;
    pointer-events: none;
}

#brandlab {
    position: absolute;
    width: 100%;
    height: 100vh;
    margin-top: -2vh;
    pointer-events: none;
}

@keyframes decrease {
    from {
        transform: scale(3);
    }

    to {
        transform: scale(1);
    }
}

@keyframes fade-in {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes move-title-brand {
    from {
        opacity: 0;
        transform: translateY(300px);
    }

    to {
        opacity: 1;
        transform: translateY(0px);
    }
}

@keyframes move-brand-paragraph {
    from {
        opacity: 0;
        transform: translateY(200px);
    }

    to {
        opacity: 1;
        transform: translateY(0px);
    }
}

@keyframes move-brand-circle {
    from {
        opacity: 0;
        transform: translateY(50vh);
    }

    to {
        opacity: 1;
        transform: translateY(0vh);
    }
}

@keyframes move-sub {
    from {
        opacity: 0;
        transform: translateX(-200px);
    }

    to {
        opacity: 1;
        transform: translateX(0px);
    }
}

@media (min-width: 3000px) {
    #brandlab {
        margin-top: 2vh;
    }

    .brandlab {
        height: 150vh;
        .brandlab-circle {
            width: 3450px;
            height: 3450px;
            margin-top: -30vh;
        }

        .front {
            margin-top: -40vh;
            .texts {
                margin-top: 40vh;
                margin-left: 7vw;

                p {
                    max-width: 1700px;
                    line-height: 100px;
                }

                h3 {
                    font-size: 50px;
                    line-height: 100px;
                    max-width: 1800px;
                    margin-bottom: 40px;
                }

                .brands {
                    margin-top: 20px;
                    gap: 50px;
                    scale: 2;
                    margin-left: 26.5vw;
                }
            }

            .brandlab-video {
                width: 1650px;
                height: 1650px;
                margin-bottom: -27vh;
                margin-left: 53vw;
            }
        }
    }
}

@media (min-width: 2000px) and (max-width: 3000px) {
    .brandlab {
        .brandlab-circle {
            width: 2450px;
            height: 2450px;
            margin-top: 0vh;
            transform: translate3d(-10vh);
        }

        .front {
            .texts {
                margin-top: 200px;
                margin-left: 8vw;

                p {
                    max-width: 1150px;
                }

                h3 {
                    font-size: 28px;
                    line-height: 56px;
                    max-width: 900px;
                }

                .brands {
                    gap: 50px;

                    .brand {
                        img {
                            max-width: none;
                            max-height: 120px;
                            object-fit: none;
                        }
                    }
                }
            }

            .brandlab-video {
                width: 1050px;
                height: 1050px;
                margin-bottom: -5vh;
                margin-left: 52vw;
            }
        }
    }
}

@media(max-width: 1600px) {
    .brandlab {
        .brandlab-circle {
            width: 1550px;
            height: 1550px;
        }

        .front {
            .texts {
                p {
                    max-width: 45%;
                }

                h3 {
                    font-size: 16px;
                    line-height: 32px;
                    max-width: 35%;
                }

                .brands {
                    .brand {
                        img {
                            max-width: 120px;
                        }
                    }
                }
            }

            .brandlab-video {
                width: 686px;
                height: 686px;
                margin-bottom: 13vh;
            }
        }
    }
}

@media (max-width: 1500px) and (max-height: 940px) {
    .brandlab {
        .brandlab-circle {
            width: 1390px;
            height: 1390px;
        }

        .front {
            h2 {
                font-size: 184px;
            }

            .texts {
                margin-top: 100px;

                p {
                    max-width: 45%;
                }
            }

            .brandlab-video {
                width: 680px;
                height: 680px;
                margin-bottom: 15vh;
            }
        }
    }
}

@media (max-width: 1450px) {
    .brandlab {
        .brandlab-circle {
            width: 1370px;
            height: 1370px;
            margin-top: -36vh;
        }

        .front {
            margin-top: -10vh;
            .texts {
                margin-left: 5.5vw;
                margin-top: 150px;

                h3 {
                    line-height: 32px;
                }

                .brands {
                    .brand {
                        img {
                            max-height: 50px;
                        }
                    }
                }
            }

            .brandlab-video {
                width: 600px;
                height: 600px;
                margin-left: 55vw;
                margin-bottom: 16vh;
            }
        }
    }
    #brandlab-navigation {
        margin-top: 20vh;
    }
    
}

@media (max-width: 1400px) and (max-height: 940px) {
    .brandlab {
        .brandlab-circle {
            width: 1270px;
            height: 1270px;
            margin-top: -26vh;
        }

        .front {
            margin-top: 0vh;
            .texts {
                margin-top: 20vh;
                margin-left: 7.5vw;

                h3 {
                    font-size: 14px;
                    line-height: 28px;
                }

                .paragraphs {
                    font-size: 14px;
                    line-height: 28px;
                }
            }

            .brandlab-video {
                width: 600px;
                height: 600px;
                margin-left: 53vw;
                margin-bottom: 25vh;
            }
        }
    }
}

@media (max-width: 1300px) and (max-height: 940px) {
    .brandlab {
        .brandlab-circle {
            width: 1180px;
            height: 1180px;
        }

        .front {
            .texts {
                margin-left: 6vw;

                h3 {
                    margin-top: -15px;
                    font-size: 14px;
                    line-height: 28px;
                }

                .brands {
                    .brand {
                        img {
                            max-height: 40px;
                        }
                    }
                }
            }

            .brandlab-video {
                width: 550px;
                height: 550px;
                margin-bottom: 33vh;
            }
        }
    }
}

@media(max-width: 1000px) {
    .brandlab {
        padding-top: 150px;
        height: 100vh;

        .brandlab-circle {
            visibility: hidden;
        }

        .brandlab-circle-mobile {
            display: none;
            background-color: var(--bg-color-four);
            border-radius: 100%;
            width: 1200px;
            height: 1200px;
            justify-content: center;
            align-items: center;
            position: absolute;
            margin-top: 65vh;
            margin-right: 45vw;
            animation: move-brand-circle 1s ease-in-out;
        }

        .front {
            margin-top: -35.5vh;

            .texts {
                margin-left: 0px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 0px;
                gap: 12px;

                h2 {
                    width: 80%;
                }

                p {
                    margin-top: -5px;
                    max-width: none;
                    width: 80%;
                    margin-right: 0px;
                }

                h3 {
                    font-size: 20px;
                    max-width: none;
                    width: 80%;
                    margin-left: 0px;
                }

                .brands {
                    flex-wrap: wrap;
                    width: 80%;
                    max-width: none;
                    gap: 20px;
                    row-gap: 20px;

                    .brand {
                        margin-top: 5px;

                        img {
                            max-width: 111.411px;
                            max-height: 32.703px;
                        }
                    }
                }
            }

            .brandlab-video {
                margin-bottom: -111vh;
                width: 800px;
                height: 800px;

                .video-circle {
                    width: 1532.015px;
                    height: 1532.015px;
                    border-radius: 100%;
                    position: absolute;
                    border: 0.509px solid var(--bg-color-four);
                    box-shadow: 0px 2.03433px 50.85825px 5.08583px rgba(0, 0, 0, 0.50);
                    margin-left: -76vw;
                    margin-top: -9vh;
                    animation: move-brand-circle 1s ease-in-out;
                }

                .video-circle-bottom {
                    width: 900px;
                    height: 900px;
                    border-radius: 100%;
                    position: absolute;
                    border: 0.509px solid var(--bg-color-four);
                    box-shadow: 0px 2.03433px 50.85825px 5.08583px rgba(0, 0, 0, 0.50);
                    animation: move-brand-circle 1s ease-in-out;
                    z-index: 6;
                    margin-top: 8vh;
                    margin-left: -2vw;
                }
            }
        }
    }

    #brandlab-navigation {
        margin-top: 20vh;
    }
}

@media(max-width: 768px){
    .brandlab{
        .front{
            .texts{
                h3{
                    font-size: 16px;
                }
            }
        }
    }
}

@media(max-width: 600px) {
    .brandlab {
        .brandlab-circle-mobile {
            width: 900px;
            height: 900px;
        }

        .front {
            .texts {
                h3 {
                    margin-top: 10px;
                }

                .brands {
                    .brand {
                        margin-top: 10px;

                        img {
                            max-width: 100px;
                            max-height: 40px;
                        }
                    }
                }
            }

            .brandlab-video {
                width: 500px;
                height: 500px;
                margin-bottom: -95vh;

                .video-circle {
                    width: 772.015px;
                    height: 772.015px;
                    margin-left: -50vw;
                    margin-top: 0vh;
                }

                .video-circle-bottom {
                    width: 500px;
                    height: 500px;
                }
            }
        }
    }
}

@media(max-width: 400px) {
    .brandlab {
        .brandlab-circle-mobile {
            width: 900px;
            height: 900px;
        }

        .front {
            .texts {
                .brands {
                    .brand {
                        margin-top: 10px;

                        img {
                            max-width: 80px;
                            max-height: 30px;
                        }
                    }
                }
            }
        }
    }
}

@media(max-height: 850px) and (max-width: 768px) {
    .brandlab {
        .brandlab-circle-mobile {
            margin-top: 53vh;
            margin-right: 89vw;
            width: 825px;
            height: 825px;
        }

        .front {
            margin-top: -30vh;

            .texts {
                h3 {
                    font-size: 18px;
                    line-height: 26px;
                    margin-top: 10px;
                }
            }

            .brandlab-video {
                width: 447.384px;
                height: 447.384px;
                margin-bottom: -80vh;

                .video-circle {
                    width: 632.015px;
                    height: 632.015px;
                    margin-left: -57vw;
                    margin-top: -17vh;
                }

                .video-circle-bottom {
                    margin-top: 5vh;
                    margin-left: 0vw;
                    width: 447.384px;
                    height: 447.384px;
                }
            }
        }
    }
}

@media(max-height: 700px) and (max-width: 768px) {
    .brandlab {
        .front {
            .texts {
                margin-top: -20%;
                p,
                h3 {
                    font-size: 12px !important;
                    line-height: 24px !important;
                }
            }

            .brandlab-video {
                width: 420px;
                height: 420px;
                margin-bottom: -70vh;
                .video-circle {
                    width: 650px;
                    height: 650px;
                    margin-left: -57vw;
                    margin-top: -5vh;
                }

                .video-circle-bottom {
                    margin-top: 5vh;
                    margin-left: 0vw;
                    width: 447.384px;
                    height: 447.384px;
                }
            }

        }
    }
}

@media(max-height: 450px)  and (max-width: 768px){
    .brandlab {
        .brandlab-circle-mobile {
            width: 625px;
            height: 625px;
        }
        .front {
            .texts {
                gap: 10px;
                margin-top: -40%;
                p,
                h3 {
                    font-size: 8px !important;
                    line-height: 16px !important;
                }
            }

            .brandlab-video {
                width: 350px;
                height: 350px;
                margin-bottom: -40vh;
                .video-circle {
                    width: 500px;
                    height: 500px;
                }

                .video-circle-bottom {
                    margin-left: -5vw;
                    width: 350px;
                    height: 350px;
                }
            }

        }
    }
}