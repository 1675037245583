.construction{
    width: 100%;
    height: 100vh;
    background-color: black;
    position: relative;
    overflow: hidden;

    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    #mobile{
        display: none;
    }
}

@media(max-width: 1110px){
    .construction{
        img{
            display: none;
        }
        #mobile{
            display: block;
        }
    }
}