.start-768 {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 100vh;
    background-color: black;
    z-index: 20;

    .animation-start{
        position: absolute;
        width: 100%;
        height: 100%;
        margin-top: 300%;
        pointer-events: none;
    }

    .animation {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: white;

        img {
            z-index: 1;
        }

        .ball {
            position: absolute;
            border-radius: 50%;
            width: 3000px;
            height: 3000px;
            background-color: black;
        }
    }



    .video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        z-index: 2;
    }

    .image-background{
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        z-index: 0;
    }


    .background{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        position: relative;
    }

    .background-ball {
        position: relative;
        width: 3000px;
        height: 3000px;
        border-radius: 50%;
        overflow: hidden;
    }
    
    .videos-container {
        z-index: 0;
        width: 100vw;
        height: 100vh;
        display: flex;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); 
        justify-content: center;
        flex-direction: column;
        background-color: var(--bg-color-primary);
        align-items: center;
    
        .video-left,
        .video-right {
            width: 220%;
            height: 220%;

            .background{
                width: 100%;
                height: 109%;
                border-radius: 100%;
                overflow: hidden;
                position: relative;
            }
        }
    
        .video-left {
            margin-top: -9%;
            z-index: 2;
            .video {
                object-position: center;
            }

        }
    }

    .center-container {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        z-index: 2;
        pointer-events: none;
        transition: translate 1s ease-in;
    }

    .center {
        width: 100%;
        height: 100%;
        min-height: 10px;
        min-width: 10px;
        position: absolute;
        z-index: 1;
        transition: transform 1s ease;

        .circle {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 858px;
            height: 858px;
            border-radius: 100%;
            overflow: hidden;
            border: 1px solid white;
            box-shadow: 0px 4px 94px 0px rgba(0, 0, 0, 0.25);

            .video{
                position: absolute;
            }
        }
    }

    .text-container-one {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 100%;
        height: 100px;
        overflow: hidden;
        margin-bottom: -60px;
    }

    .text-container-two {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 100%;
        height: 100px;
        overflow: hidden;
        margin-top: -90px;
    }

    .text-container-center {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100vw;
        height: 288px;
    }

    .text {
        text-align: center;
        position: absolute;
        z-index: 6;
        top: 50%;
        left: 50%;
        color: var(--color-text-primary);
        transform: translate(-50%, -50%);
        font-style: normal;

        h1 {
            font-size: 180px;
            font-weight: 400;
            font-style: normal;
            transition: transform 0.7s ease-out;
            animation: fade-in 1.5s ease-in;
        }

        h2 {
            font-size: 72px;
            font-weight: 300;
            line-height: 96px;
            animation: fade-in 1.5s ease-in;
        }
        .text-one{
            transition: transform 0.5s ease-out;
        }
        .text-three{
            transition: transform 0.9s ease-out;
        }
    }

    .circle-top-768,
    .circle-bottom-768{
        position: absolute;
        z-index: 7;
        width: 1000px;
        height: 1000px;
        border: 1px solid #FFF;
        border-radius: 100%;
        box-shadow: 0px 4px 94px 0px rgba(0, 0, 0, 0.568);
        pointer-events: none;
        opacity: 1;
        animation: fade 0.6s ease-in;
    }

    .circle-top-768 {
        top: 0%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .circle-bottom-768{
        top: 100%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

@keyframes fade {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@media(max-width: 1200px){
    .start{
        .text-container-center {
            overflow: visible;
        }
    }
}

@media(max-width: 900px){
    .start-768{
        .text{
            h1{
                font-size: 140px;
            }
            h2 {
                font-size: 48px;
            }
        }
    }
}

@media(max-width: 550px){
    .start-768{
        .text-container-one {
            margin-bottom: -110px;
        }
    
        .text-container-two {
            margin-top: -120px;
        }
        .text{
            h1{
                font-size: 120px;
            }
            h2 {
                font-size: 40px;
            }
        }
    }
}
@media(max-width: 500px){
    .start-768{
        .text{
            h1{
                font-size: 100px;
            }
            h2 {
                font-size: 32px;
            }
        }
    }
}
@media(max-width: 400px){
    .start-768{
        .text{
            h1{
                font-size: 92px;
            }
            h2 {
                font-size: 32px;
            }
        }
    }
}

@media(max-width: 320px){
    .start-768{
        .text{
            h1{
                font-size: 80px;
            }
            h2 {
                font-size: 28px;
            }
        }
    }
}

@media(max-height: 1200px){
    .start-768{
        .center{
            .circle{
                width: 850px;
                height: 850px;
            }
        }
        .circle-top-768,
        .circle-bottom-768{
            width: 750px;
            height: 750px;
        }
    }
}

@media(max-height: 1051px){
    .start-768{
        .center{
            .circle{
                width: 820px;
                height: 820px;
            }
        }
        .circle-top-768,
        .circle-bottom-768{
            width: 650px;
            height: 650px;
        }
    }
}

@media(max-height: 960px){
    .start-768{
        .center{
            .circle{
                width: 606px;
                height: 606px;
            }
        }
        .circle-top-768,
        .circle-bottom-768{
            width: 650px;
            height: 650px;
        }
    }
}

@media(max-height: 920px){
    .start-768{
        .center{
            .circle{
                width: 446px;
                height: 446px;
            }
        }
        .circle-top-768,
        .circle-bottom-768{
            width: 550px;
            height: 550px;
        }
    }
}

@media(max-height: 800px){
    .start-768{
        .center{
            .circle{
                width: 456px;
                height: 456px;
            }
        }
        .circle-top-768,
        .circle-bottom-768{
            width: 500px;
            height: 500px;
        }
    }
}

@media(max-height: 750px){
    .start-768{
        .center{
            .circle{
                width: 446px;
                height: 446px;
            }
        }
        .circle-top-768,
        .circle-bottom-768{
            width: 430px;
            height: 430px;
        }
    }
}

@media(max-height: 700px){
    .start-768{
        .center{
            .circle{
                width: 440px;
                height: 440px;
            }
        }
        .circle-top-768,
        .circle-bottom-768{
            width: 400px;
            height: 400px;
        }
    }
}

@media(max-height: 570px){
    .start-768{
        .videos-container {
        
            .video-left {
                z-index: 2;
                .video {
                    object-position: center;
                }
    
            }
        }
        .center{
            .circle{
                width: 350px;
                height: 350px;
            }
        }
        .circle-top-768,
        .circle-bottom-768{
            width: 350px;
            height: 350px;
        }
    }
}

@media(max-height: 450px){
    .start-768{
        .videos-container {
        
            .video-left {
                z-index: 2;
                margin-bottom: -5%;
                .video {
                    object-position: center;
                }
    
            }
        }
        .text{
            h1{
                font-size: 60px;
            }
            h2 {
                font-size: 20px;
            }
        }
        .center{
            .circle{
                width: 300px;
                height: 300px;
            }
        }
        .text-container-one {
            margin-bottom: -150px;
        }
    
        .text-container-two {
            margin-top: -150px;
        }
        .circle-top-768,
        .circle-bottom-768{
            width: 250px;
            height: 250px;
        }
    }
}