.home {
    overflow: hidden;
    height: 100%;
	max-width: 100vw;
    .titles {
        font-size: 220px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .paragraphs {
        font-family: var(--font-secondary);
        font-size: 20px;
        font-style: normal;
        font-weight: 300;
        line-height: 40px;
        letter-spacing: 1.32px;
    }

    .loading {
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: white;

        .spinner {
            margin-top: 20px;
        }
    }
}

.modal {
    h2 {
        font-weight: 700;
        font-size: 32px;
    }
}

@media (min-width: 3000px) {
    .home {
        .titles {
            font-size: 474px;
        }

        .paragraphs {
            font-size: 50px;
            line-height: 100px;
        }

        .loading {
            scale: 3;
        }
    }
}

@media (min-width: 2000px) and (max-width: 3000px) {
    .home {
        .titles {
            font-size: 304px;
        }

        .paragraphs {
            font-size: 28px;
            line-height: 56px;
        }
    }
}

@media(max-width: 1600px) {
    .home {
        .titles {
            font-size: 186px;
        }

        .paragraphs {
            font-size: 16px;
            line-height: 32px;
        }
    }
}

@media(max-width: 1440px) {
    .home {
        .titles {
            font-size: 180px !important;
        }

        .paragraphs {
            line-height: 32px;
            font-size: 16px;
        }
    }
}

@media(max-width: 1400px) {
    .home {
        .titles {
            font-size: 164px !important;
        }

        .paragraphs {
            line-height: 30px;
            font-size: 16px;
        }
    }
}

@media(max-width: 1280px) {
    .home {
        .titles {
            font-size: 156px !important;
        }
    }
}

@media(max-width: 1366px) {
    .home {
        .paragraphs {
            font-size: 14px;
            line-height: 28px;
        }
    }
}

@media(max-width: 1150px) {
    .home {
        .titles {
            font-size: 132px !important;
        }

        .paragraphs {
            font-size: 14px !important;
            line-height: 28px !important;
        }
    }
}

@media(max-width: 1000px) {
    .home {
        .titles {
            font-size: 120px !important;
        }

        .paragraphs {
            font-size: 20px !important;
            line-height: 40px !important;
        }
    }
}

@media(max-width: 768px) {
    .home {
        .titles {
            font-size: 80px !important;
        }

        .paragraphs {
            font-size: 16px !important;
            line-height: 32px !important;
        }
    }
}


@media(max-height: 700px) and (max-width: 768px) {
    .home {
        .titles {
            font-size: 72px !important;
        }

        .paragraphs {
            font-size: 12px !important;
            line-height: 24px !important;
        }
    }
}

@media(max-height: 450px) and (max-width: 768px) {
    .home {
        .titles {
            font-size: 50px !important;
        }

        .paragraphs {
            font-size: 8px !important;
            line-height: 16px !important;
        }
    }
}