.project {
    cursor: pointer;
    color: white;
    display: flex;
    align-items: flex-end;
    border-radius: 50px !important;
    overflow: hidden;
    position: relative;
    opacity: 0;
    transform: translateY(300px);
    transition: 0.9s ease-out;

    .project-shadown {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 2;
        box-shadow: 0px -80px 24px -23px rgba(0, 0, 0, 0.8) inset;
        -webkit-box-shadow: 0px -80px 24px -23px rgba(0, 0, 0, 0.8) inset;
        -moz-box-shadow: 0px -80px 24px -23px rgba(0, 0, 0, 0.8) inset;
    }

    &.show {
        opacity: 1;
        transform: translateY(0px);
    }

    &:hover {
        .project-img {
            scale: 1.2;

            video {
                z-index: 2;
            }
        }
    }

    .project-img {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 105%;
        border-radius: 50px !important;
        position: absolute;
        transition: 1s ease-in;

        video,
        img {
            position: absolute;
            width: 100%;
            height: 120%;
            object-fit: cover;
            object-position: center;
            transition: transform 0s ease-in-out;
        }

        img {
            z-index: 1;
        }

        video {
            z-index: 0;
        }
    }

    .text-project {
        display: flex;
        pointer-events: none;
        flex-direction: column;
        margin-left: 40px;
        margin-bottom: 50px;
        z-index: 3;
        transition: transform 0.1s ease-in-out;

        h3 {
            font-size: 44px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        .stream {
            display: flex;
            justify-content: left;
            align-items: center;
            text-align: center;
            font-family: var(--font-secondary);

            p {
                font-size: 21px;
                font-style: normal;
                line-height: normal;
            }

            .bold {
                font-weight: 700;
            }

            .line {
                margin-left: 20px;
                margin-right: 20px;
                width: 1px;
                height: 20px;
                background-color: white;
            }
        }
    }
}

@keyframes move-project {
    from {
        opacity: 0;
        border-radius: 50px;
        transform: translateY(300px);
    }

    to {
        opacity: 1;
        border-radius: 50px;
        transform: translateY(0px);
    }
}

@media(max-width: 1300px) {
    .project {
        .text {
            h3 {
                font-size: 40px;
            }
        }
    }
}

@media(max-width: 1150px) {
    .project {
    }
}

@media(max-width: 600px) {
    .project {
        .text {
            margin-bottom: 40px;
            h3 {
                font-size: 27px;
            }

            .stream {
                p {
                    font-size: 13.113px;
                }
                .line{
                    height: 15px;
                }
            }
        }
    }
}