.start {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 100vh;
    background-color: black;
    z-index: 20;

    .animation {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: white;

        img {
            z-index: 1;
        }

        .ball {
            position: absolute;
            border-radius: 50%;
            width: 3000px;
            height: 3000px;
            background-color: black;
        }
    }



    .video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        z-index: 2;
        position: relative;
    }

    .image-background{
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        z-index: 0;
    }

    .background{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        position: relative;
        overflow: hidden;
    }

    .background-ball {
        position: relative;
        width: 3000px;
        height: 3000px;
        border-radius: 50%;
        overflow: hidden;
    }
    
    .videos-container {
        z-index: 0;
        width: 110vw;
        height: 100vh;
        display: flex;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); 
        justify-content: center;
        background-color: var(--bg-color-primary);
        align-items: center;
        overflow: hidden;
    
        .video-left,
        .video-right {
            width: 100%;
            height: 100%;
        }
    
        .video-left {
            .video {
                object-position: right;
            }
        }
    }

    .center-container {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        z-index: 2;
        pointer-events: none;
        transition: translate 1s ease-in;
    }

    .center {
        width: 100%;
        height: 100%;
        min-height: 10px;
        min-width: 10px;
        position: absolute;
        z-index: 1;
        transition: transform 1s ease;

        .circle {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 1156px;
            height: 1156px;
            border-radius: 100%;
            overflow: hidden;
            border: 1px solid white;
            box-shadow: 0px 4px 94px 0px rgba(0, 0, 0, 0.25);
        }
    }

    .text-container-one {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 100%;
        height: 100px;
        overflow: hidden;
        transition: transform 0.3s ease-out;
    }

    .text-container-two {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 100%;
        height: 100px;
        overflow: hidden;
        margin-top: -40px;
        transition: transform 0.3s ease-out;
    }

    .text-container-center {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 100%;
        height: 288px;
        overflow: hidden;
        transition: transform 0.3s ease-out;
    }

    .text {
        text-align: center;
        position: absolute;
        z-index: 6;
        top: 50%;
        left: 50%;
        color: var(--color-text-primary);
        transform: translate(-50%, -50%);
        font-style: normal;

        h1 {
            font-size: 280px;
            font-weight: 400;
            font-style: normal;
            line-height: 412px;
            transition: transform 0.7s ease-out;
            animation: fade-in 1.5s ease-in;
        }

        h2 {
            font-size: 96px;
            font-weight: 300;
            line-height: 96px;
            animation: fade-in 1.5s ease-in;
        }
        .text-one{
            transition: transform 0.5s ease-out;
        }
        .text-three{
            transition: transform 0.9s ease-out;
        }
    }

    .circle-top-1280,
    .circle-bottom-1280{
        position: absolute;
        z-index: 7;
        width: 1156px;
        height: 1156px;
        border-radius: 100%;
        border: 1px solid #FFF;
        box-shadow: 0px 4px 94px 0px rgba(0, 0, 0, 0.568);
        pointer-events: none;
        opacity: 1;
        animation: fade 0.6s ease-in;
    }

    .circle-top-1280 {
        top: 0%;
        left: 108%;
        transform: translate(-50%, -50%);

        &.sticky{
            top: 0;
            transform: translate(-50%, -50%) translate(-36.2vw, 60vh) !important;
            position: fixed !important;
        }
    }

    .circle-bottom-1280{
        top: 107%;
        left: -6%;
        transform: translate(-50%, -50%);
    }
}

@keyframes fade {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@media (max-height: 1079px) and (max-width: 1400px) {
    .start {
        .center {
            .circle {
                width: 900px;
                height: 900px;
            }
        }

        .circle-top-1280,
        .circle-bottom-1280 {
            width: 900px;
            height: 900px;
        }

        .text {
            h1 {
                font-size: 230px;
                line-height: normal;
            }
        }
    }
}


@media (max-width: 1799px) and (max-height: 900px) {
    .start {
        .center {
            .circle {
                width: 1050px;
                height: 1050px;
            }
        }

        .circle-top-1280,
        .circle-bottom-1280 {
            width: 1050px;
            height: 1050px;
        }

        .text {
            h1 {
                font-size: 250px;
                line-height: normal;
            }
        }
    }
}

@media (max-width: 1600px) and (max-height: 900px) {
    .start {
        .center {
            .circle {
                width: 950px;
                height: 950px;
            }
        }

        .circle-top-1280,
        .circle-bottom-1280 {
            width: 950px;
            height: 950px;
        }

        .text {
            h1 {
                font-size: 256px;
                line-height: normal;
            }
        }
    }
}

@media (max-width: 1500px) and (max-height: 900px) {
    .start {
        .center {
            .circle {
                width: 900px;
                height: 900px;
            }
        }

        .circle-top-1280,
        .circle-bottom-1280 {
            width: 900px;
            height: 900px;
        }

        .text {
            h1 {
                font-size: 200px;
                line-height: normal;
            }

            h2 {
                font-size: 72px;
            }
        }
    }
}

@media (max-width: 1400px) and (max-height: 900px) {
    .start {
        .center {
            .circle {
                width: 800px;
                height: 800px;
            }
        }

        .text {
            h1 {
                font-size: 200px !important;
            }

            h2 {
                font-size: 64px !important;
            }
        }

        .circle-top-1280,
        .circle-bottom-1280 {
            width: 800px;
            height: 800px;
        }
        .text-container-one{
            margin-bottom: -50px;
        }
        .text-container-two{
            margin-top: -80px;
        }
    }
}

@media (max-width: 1400px) {
    .start {
        .text {
            h1 {
                font-size: 200px !important;
            }

            h2 {
                font-size: 64px !important;
            }
        }
    }
}


@media (max-width: 1299px) and (max-height: 900px) {
    .start {
        .center {
            .circle {
                width: 750px;
                height: 750px;
            }
        }

        .circle-top-1280,
        .circle-bottom-1280 {
            width: 750px;
            height: 750px;
        }
    }
}

@media(max-width: 1200px){
    .start{
        .text-container-center {
            overflow: visible;
        }
    }
}

@media (max-width: 768px) {
    .start {
        .videos-container {
            flex-direction: column;
        }

        .center {
            .circle {
                width: 768px;
                height: 768px;
            }
        }

        .text {
            h1 {
                font-size: 200px;
            }

            h2 {
                font-size: 72px;
            }
        }

        .circle-top-1280,
        .circle-bottom-1280 {
            width: 850px;
            height: 850px;
            border: 0.476px solid #FFF;

        }

        .circle-top-1280 {
            top: -30%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        .circle-bottom-1280 {
            top: 127%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}