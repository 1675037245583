.modalproject {
    width: 100%;
    height: 100%;
    transition: background-color 1150ms ease-in-out;
    transition-delay: 1000ms;
    position: fixed;
    top: 0;
    overflow-x: hidden;
    scroll-behavior: smooth;
    color: white;
    z-index: 101;
    background-color: var(--bg-color-primary);

    h2 {
        width: 100%;
        font-size: 128px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .project-modal {
        width: 100%;
        height: 800px;
        justify-content: left;
        align-items: left;
        flex-direction: column;
        animation: fade-in 1s ease-in-out forwards;
        padding-top: 150px;

        .title {
            z-index: 6;
            position: absolute;
            margin-left: 88px;
            margin-top: -35px;
            animation: move-up 0.5s ease-out;
        }

        .project-about {
            z-index: 0;
            margin-top: 50px;
            margin-left: 88px;
            width: 100%;
            height: 100%;
            display: flex;
            font-family: var(--font-secondary);
            justify-content: left;

            .text {
                height: 100%;
                max-width: 530px;
                display: flex;
                flex-direction: column;
                margin-right: 49px;
                margin-top: 70px;
                padding-bottom: 50px;
                gap: 32px;

                .stream {
                    display: flex;
                    justify-content: left;
                    align-items: center;
                    animation: move-up 0.5s ease-out;
                    font-size: 30px;

                    .type {
                        height: 100%;
                        font-style: normal;
                        font-weight: 300;
                        line-height: normal;
                    }


                    .line {
                        margin-left: 24px;
                        margin-right: 24px;
                        width: 3px;
                        height: 70%;
                        background-color: white;
                    }

                    .streamname {
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                    }
                }

                .paragraph {
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 200;
                    opacity: 0.8;
                    line-height: 40px;
                    animation: move-up 0.6s ease-out;
                }

                .credits {
                    display: flex;
                    justify-content: left;
                    align-items: left;
                    flex-wrap: wrap;
                    gap: 36px;


                    .credit {
                        max-height: 50px;
                        display: flex;
                        gap: 16px;

                        p {
                            font-size: 20px;
                            font-style: normal;
                            font-weight: 300;
                            line-height: normal;
                        }

                        .name {
                            font-weight: 600;
                        }
                    }

                    .createdBy {
                        animation: move-up 0.7s ease-out;
                    }

                    .direction {
                        animation: move-up 0.9s ease-out;
                    }

                    .support {
                        animation: move-up 1.1s ease-out;
                    }

                    .realization {
                        animation: move-up 1.3s ease-out;
                    }


                    .label5 {
                        animation: move-up 1.5s ease-out;
                    }
                }
            }

            .image {
                width: 1253px;
                overflow: hidden;
                height: 100%;
                animation: move-up-3 1s ease-out;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                    transition: 1s ease-in;

                    &:hover {
                        scale: 1.2;
                    }
                }
            }
        }
    }

    .gallery {
        width: 100vw;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        animation: move-up-3 1s ease-out;

        .video {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 95vh;
            overflow: hidden;
            width: 100%;
            animation: moveUp 1.5s;
            z-index: 2;
            cursor: pointer;
            filter: drop-shadow(0px 4.383561611175537px 92.05479431152344px rgba(0, 0, 0, 0.90));

            &:hover {
                img {
                    scale: 1.2;
                }
            }

            img {
                width: 100%;
                height: 100%;
                transition: 1s ease-in;
                object-fit: cover;
                object-position: center;
            }

            .play {
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                width: 150px;
                height: 150px;
                border-radius: 100%;
                background: var(--bg-color-play);
                opacity: 0.6;

                svg {
                    width: 100%;
                    height: 100%;
                    margin-left: 19px;
                }
            }
        }

        .images {
            display: flex;
            justify-content: center;
            z-index: 0;
            width: 100%;
            height: 600px;

            .image {
                width: 100%;
                height: 100%;
                overflow: hidden;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                    transition: 1s ease-in;

                    &:hover {
                        scale: 1.2;
                    }
                }
            }
        }

        .middle {
            height: 886px;
            width: 100%;
            z-index: 2;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
                transition: 1s ease-in;

                &:hover {
                    scale: 1.2;
                }
            }
        }
    }

    .footer {
        width: 100%;
        padding-top: 200px;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 50px;
        display: flex;
        align-items: center;
        flex-direction: column;

        .title {
            display: flex;
            z-index: 2;
            padding: 0px 20px;
            justify-content: space-between;
            width: 100%;
            font-size: 128px;

            .arrow-container {
                cursor: pointer;
                display: flex;
                width: 200px;
                overflow: hidden;
                position: relative;
                margin-top: 20px;

                .left-primary,
                .left-secondy,
                .right-primary,
                .right-secondy {
                    width: 200px;
                    position: absolute;
                    transition: transform 0.3s, opacity 0.3s, transform-origin 0.3s;
                }

                .left-primary {
                    z-index: 2;
                    transform-origin: left center;
                    opacity: 1;
                }

                .right-primary {
                    z-index: 2;
                    transform-origin: right center;
                    opacity: 1;
                }

                .right-secondy {
                    z-index: 1;
                    transform-origin: left center;
                    opacity: 0;
                    transform: scaleX(0);
                }

                .left-secondy {
                    z-index: 1;
                    transform-origin: right center;
                    opacity: 0;
                    transform: scaleX(0);
                }
            }

            .arrow-container:hover {

                .left-primary,
                .right-primary {
                    transform: scaleX(0);
                    opacity: 0;
                }

                .left-secondy,
                .right-secondy {
                    transform: scaleX(1);
                    opacity: 1;
                }
            }
        }

        .swiper-no-swiping {
            touch-action: none;
        }
        .outher-projects {
            width: 95%;
            height: 500px;
            display: flex;
            margin-top: -50px;
            padding-right: 20px;
            z-index: 0;

            .swiper-wrapper {
                display: flex;
            }

            .outher-project {
                width: 100%;
                height: 100%;
                overflow: hidden;
                position: relative;
                transition: 1s ease-in;
                cursor: pointer;

                &:hover {
                    .project-img{
                        video {
                            z-index: 2;
                        }
                    }
                }

                .project-shadow {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    z-index: 3;
                    box-shadow: 0px -120px 24px -23px rgba(0, 0, 0, 0.8) inset;
                    -webkit-box-shadow: 0px -120px 24px -23px rgba(0, 0, 0, 0.8) inset;
                    -moz-box-shadow: 0px -120px 24px -23px rgba(0, 0, 0, 0.8) inset;
                }

                .project-img {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                    transition: 1s ease-in;

                    video,
                    img {
                        position: absolute;
                        width: 100%;
                        height: 120%;
                        object-fit: cover;
                        object-position: center;
                    }

                    img {
                        z-index: 1;
                    }

                    video {
                        z-index: 0;
                    }
                }

                .info {
                    display: flex;
                    z-index: 4;
                    flex-direction: column;
                    position: absolute;
                    margin-top: -120px;
                    padding-left: 50px;
                    padding-right: 50px;

                    h3 {
                        font-size: 44px;
                        font-family: var(--font-primary);
                    }

                    .text-project {
                        margin-top: 10px;
                        display: flex;
                        justify-content: left;
                        align-items: center;
                        font-family: var(--font-secondary);

                        p {
                            font-size: 20px;
                        }

                        .line {
                            margin-left: 20px;
                            margin-right: 20px;
                            width: 1px;
                            height: 23px;
                            background-color: white;
                        }

                        p.stream {
                            font-weight: 700;
                        }
                    }
                }
            }
        }
    }
}

.modalproject.background {
    background-color: var(--bg-color-secondary);
}

@keyframes fade-in {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes move-up {
    from {
        opacity: 0;
        transform: translateY(500px);
    }

    to {
        transform: translateY(0px);
        opacity: 1;
    }
}

@keyframes move-up-2 {
    from {
        transform: translateY(300px);
    }

    to {
        transform: translateY(0px);
    }
}

@keyframes move-up-3 {
    from {
        transform: translateY(500px);
    }

    to {
        transform: translateY(0px);
    }
}

@media(min-width: 3000px) {
    .modalproject {
        h2 {
            font-size: 244px;
        }

        .project-modal {
            padding-top: 350px;
            height: 1500px;

            .project-about {
                margin-top: 100px;

                .text {
                    margin-top: 150px;
                    max-width: 1530px;
                    gap: 72px;

                    .stream {
                        font-size: 72px;

                        .line {
                            margin-left: 54px;
                            margin-right: 54px;
                            width: 10px;
                        }
                    }

                    .paragraph {
                        font-size: 80px;
                        line-height: normal;
                    }

                    .credits {
                        gap: 72px;

                        .credit {
                            p {
                                font-size: 56px;
                            }
                        }
                    }
                }

                .image {
                    width: 100%;
                }
            }
        }

        .gallery {
            .video {
                .play {
                    width: 450px;
                    height: 450px;

                    svg {
                        margin-left: 59px;
                    }
                }
            }

            .images {
                height: 1200px;
            }

            .middle {
                height: 1786px;
            }
        }

        .footer {
            .title {
                margin-bottom: -40px;
                font-size: 256px;

                .arrow-container {
                    margin-top: 130px;
                    padding: 0px 80px;
                    scale: 2.5;
                }
            }

            .outher-projects {
                height: 1000px;

                .outher-project {
                    .info {
                        margin-top: -250px;

                        h3 {
                            font-size: 88px;
                        }

                        .text-project {
                            p {
                                font-size: 62px;
                            }

                            .line {
                                margin-left: 50px;
                                margin-right: 50px;
                                width: 6px;
                                height: 53px;
                                background-color: white;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media(min-width: 2000px) and (max-width: 3000px) {
    .modalproject {
        h2 {
            font-size: 144px;
        }

        .project-modal {
            .project-about {
                .text {
                    max-width: 930px;

                    .paragraph {
                        font-size: 32px;
                    }

                    .credits {
                        .credit {
                            p {
                                font-size: 28px;
                            }
                        }
                    }
                }

                .image {
                    width: 100%;
                }
            }
        }

        .footer {
            .title {
                font-size: 160px;

                .arrow-container {
                    margin-top: 60px;
                    scale: 1.5;
                }
            }

            .swiper-no-swiping {
                touch-action: none;
            }

            .outher-projects {
                height: 700px;

                .outher-project {
                    .info {
                        margin-top: -160px;

                        h3 {
                            font-size: 52px;
                        }

                        .text-project {
                            p {
                                font-size: 28px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media(max-width: 1680px) {
    .modalproject {
        h2 {
            font-size: 112px;
        }

        .project-modal {
            .project-about {
                margin-top: 40px;
            }
        }
    }
}

@media(max-width: 1500px) {
    .modalproject {
        .project-modal {
            .project-about {
                .text {
                    width: 100%;
                }

                .image {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}

@media(max-width: 1400px) {
    .modalproject {
        h2 {
            font-size: 80px;
        }

        .project-modal {
            .project-about {
                margin-top: 20px;
            }
        }

    }
}

@media(max-width: 1300px) {
    .modalproject {
        .footer {
            .outher-projects {
                .outher-project {
                    .info {
                        h3 {
                            font-size: 40px;
                        }
                    }
                }
            }
        }
    }
}

@media(max-width: 1000px) {
    .modalproject {

        h2 {
            width: 100%;
            font-size: 80px;
        }

        .project-modal {
            height: auto;

            .title {
                display: flex;
                position: relative;
                justify-content: center;
                align-items: center;
                text-align: center;
                margin-left: 0px;
            }

            .project-about {
                display: flex;
                flex-direction: column-reverse;
                margin-top: -16px;
                margin-left: 0px;
                height: 100%;

                .image {
                    overflow: visible;
                    width: 200%;
                    min-height: 604px;
                    margin-left: -50%;
                    pointer-events: none;

                    img {
                        min-height: 604px;
                        object-position: center;
                    }
                }

                .text {
                    padding: 38px;
                    margin-top: 0px;

                    .stream {
                        font-size: 24px;

                        .line {
                            height: 20px;
                        }
                    }

                    .paragraph {
                        font-size: 20px;
                        line-height: 40px;

                        p {
                            width: 450px;
                        }
                    }

                    .credits {
                        flex-direction: column;
                        width: none;

                        .credit {
                            gap: 12px;

                            p {
                                font-size: 20px;
                            }
                        }
                    }
                }
            }
        }

        .gallery {
            margin-top: 72px;

            .video {
                width: 100%;
                height: 420px;

                .play {
                    width: 54px;
                    height: 54px;

                    svg {
                        width: 80%;
                        height: 80%;
                        margin-left: 5px;
                    }
                }
            }

            .images {
                height: auto;
                flex-direction: column;

                .image {
                    width: 100%;
                    height: 420px;
                }
            }

            .middle {
                width: 100%;
                height: 420px;
            }
        }

        .footer {
            padding-top: 150px;
            padding-left: 10px;
            padding-right: 10px;

            .title {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0;
                text-align: center;
                font-size: 80.367px;

                .arrow-container {
                    display: none;
                }
            }
            .swiper-no-swiping {
                touch-action: auto;
            }

            .outher-projects {
                margin-top: -12px;
                height: auto;
                .swiper-wrapper {
                    flex-direction: column;
                }

                .outher-project {
                    width: 95vw !important;
                    height: 410.314px;
                    pointer-events: stroke !important; 
                    .info {
                        margin-top: -100px;
                        padding-left: 20px;

                        h3 {
                            font-size: 32px;
                        }

                        .text-project {
                            margin-top: 5px;
                            .line{
                                height: 15px;
                            }
                            p {
                                font-size: 20px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media(max-width: 768px) {
    .modalproject {

        h2 {
            width: 100%;
            font-size: 60px;
        }

        .project-modal {
            height: auto;

            .title {
                display: flex;
                position: relative;
                justify-content: center;
                align-items: center;
                text-align: center;
                margin-left: 0px;
            }

            .project-about {
                display: flex;
                flex-direction: column-reverse;
                margin-top: -16px;
                margin-left: 0px;
                height: 100%;

                .image {
                    overflow: visible;
                    width: 200%;
                    min-height: 604px;
                    margin-left: -50%;
                    pointer-events: none;

                    img {
                        min-height: 604px;
                        object-position: center;
                    }
                }

                .text {
                    padding: 38px;
                    margin-top: 0px;

                    .stream {
                        font-size: 16px;

                        .line {
                            height: 20px;
                        }
                    }

                    .paragraph {
                        font-size: 16px;
                        line-height: 26px;

                        p {
                            width: 314px;
                        }
                    }

                    .credits {
                        flex-direction: column;
                        width: none;

                        .credit {
                            align-items: center;
                            gap: 12px;

                            p {
                                font-size: 20px;
                            }
                        }
                    }
                }
            }
        }

        .gallery {
            margin-top: 72px;

            .video {
                width: 100%;
                height: 218.206px;

                .play {
                    width: 54px;
                    height: 54px;

                    svg {
                        width: 80%;
                        height: 80%;
                        margin-left: 5px;
                    }
                }
            }

            .images {
                height: auto;
                flex-direction: column;

                .image {
                    width: 100%;
                    height: 218.206px;
                }
            }

            .middle {
                width: 100%;
                height: 218.206px;
            }
        }

        .footer {
            padding-top: 150px;
            padding-left: 10px;
            padding-right: 10px;

            .title {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0;
                text-align: center;
                font-size: 40.367px;

                .arrow-container {
                    display: none;
                }
            }
            .swiper-no-swiping {
                touch-action: auto;
            }

            .outher-projects {
                margin-top: -12px;
                height: auto;
                .swiper-wrapper {
                    flex-direction: column;
                }

                .outher-project {
                    width: 95vw !important;
                    height: 210.314px;
                    pointer-events: stroke !important; 
                    .info {
                        margin-top: -65px;
                        padding-left: 20px;

                        h3 {
                            font-size: 20px;
                        }

                        .text-project {
                            margin-top: 5px;
                            .line{
                                height: 15px;
                            }
                            p {
                                font-size: 13px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media(max-width: 768px) {
    .modalproject {
        .project-modal {

            .project-about {
                .text {

                    .credits {
                        .credit {
                            p {
                                max-width: 50%;
                                font-size: 16px;
                            }
                        }
                    }
                }
            }
        }

        .footer{
            .outher-projects{
                .outher-project{
                    .project-shadow{
                        box-shadow: 0px -80px 24px -23px rgba(0, 0, 0, 0.8) inset;
                        -webkit-box-shadow: 0px -80px 24px -23px rgba(0, 0, 0, 0.8) inset;
                        -moz-box-shadow: 0px -80px 24px -23px rgba(0, 0, 0, 0.8) inset;
                    }
                }
            }
        }
    }
}