.projects {
    width: 100%;
    height: 100%;
    min-width: 100%;
    min-height: 120vh;
    display: flex;
    justify-content: left;
    flex-direction: column;
    z-index: 10;
    position: relative;
    background-color: var(--bg-color-primary);

    .project-title {
        width: 100%;
        display: flex;
        justify-content: right;
        align-items: right;
        margin-left: -10%;
        margin-top: 150px;
        z-index: 5;
        transition: opacity 1s ease-in-out;
        opacity: 0;

        &.show {
            opacity: 1;
        }

        h1 {
            opacity: 1;
            color: white;
            margin-left: 72px;
        }
    }

    .row {
        padding-left: 36px;
        width: 100%;
        max-width: 100vw;
        height: 100%;
        display: flex;
        gap: 36px;

        .project{
            max-width: calc(100vw - 62px);
        }

        .show {
            opacity: 1;
        }
    }

    .group-projects {
        display: flex;
        flex-direction: column;
        padding-right: 62px;
        gap: 36px;
    }

    .list-project {
        display: flex;
        flex-wrap: wrap;
        gap: 36px;
        width: 100%;
        z-index: 4;
        height: 100%;
        margin-top: -60px;
        transition: opacity 1s ease-in-out;
        padding-bottom: 200px;
    }
}

@media (min-width: 3000px) {
    .projects {
        .list-project {
            margin-top: 1730px;
            scale: 2;
            margin-left: 50vw;
            padding-bottom: 700px;
        }
    }
}

@media (min-width: 2000px) and (max-width: 3000px) {
    .projects {
        .project-title {
            width: 100%;
            display: flex;
            justify-content: right;
            align-items: right;
            margin-left: -10%;
            margin-top: 150px;
            z-index: 5;
            transition: opacity 1s ease-in-out;
            opacity: 0;

            &.show {
                opacity: 1;
            }

            h1 {
                opacity: 1;
                color: white;
                margin-left: 72px;
            }
        }

        .list-project {
            margin-top: 295px;
            scale: 1.30;
            margin-left: 16.5vw;
            padding-bottom: 500px;
        }
    }
}

@media(max-width: 1680px) {
    .projects {
        .group-projects {
            .project {
                max-width: 60vw;
            }
        }

        .list-project {
            margin-top: -50px;
        }
    }
}

@media(max-width: 1280px) {
    .projects {
        .list-project {
            margin-top: -43px;
        }
    }
}

@media(max-width: 1400px) {
    .projects {
        .group-projects {
            .project {
                max-width: 50vw;
            }
        }
    }
}

@media(max-width: 1250px) {
    .projects {
        .row {
            width: 100%;
            height: 100%;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            padding-left: 0px;

            .project {
                max-width: none;
                border-radius: 50px;
                width: 95vw !important;
                height: 500px !important;
            }
        }

        .group-projects {
            height: 100%;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            padding-right: 0px;

            .project {
                border-radius: 50px;
                max-width: none;
                width: 95vw !important;
                height: 500px !important;
            }
        }
    }
}

@media(max-width: 1150px) {
    .projects {
        .project-title {
            display: flex;
            justify-content: left;
            align-items: left;
            margin-top: 200px;
            margin-left: 10px;
            .titles{
                padding-left: 0px;
                margin-left: 0px;
            }
        }

        .list-project {
            margin-top: 23px;
            padding-bottom: 0px;
        }

        .row {
            width: 100%;
            height: 100%;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            padding-left: 0px;
            gap: 23px;

            .project {
                border-radius: 50px;
                width: 95vw !important;
                height: 500px !important;
            }
        }

        .group-projects {
            height: 100%;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            padding-right: 0px;

            .project {
                border-radius: 50px;
                max-width: none;
                width: 95vw !important;
                height: 500px !important;
            }
        }
    }
}

@media(max-width: 500px) {
    .projects {
        .row {
            .project {
                border-radius: 50px;
                height: 299.725px !important;
            }
        }

        .group-projects {
            .project {
                border-radius: 50px;
                height: 299.725px !important;
            }
        }
    }
}

@keyframes fade-in {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}