.about {
    width: 100%;
    min-height: 100vh;
    color: var(--color-text-primary);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    top: 0;
    z-index: 20;

    #mobile-animation-about{
        display: none;
        margin-top: 500%;
    }

    .about-container, .about-container-mobile {
        width: 100%;
        margin-top: 1%;
        min-height: 110vh;
        display: none;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        z-index: 6;
        animation: fade-about 1s ease-in-out forwards;
    }

    .about-text {
        display: flex;
        justify-content: end;
        align-items: center;
        flex-direction: column;
        z-index: 7;
        width: 100%;
        height: 100%;
        margin-left: 500px;
        animation: fade-about 1s ease-in-out forwards;

        .about-title {
            flex-direction: column;
            color: var(--color-text-third);

            h2 {
                animation: move-left 1.5s ease-in-out forwards;
                margin-top: -40px;
                margin-left: -90px;
            }

            h3 {
                font-size: 200px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin-left: -284px;
                animation: move-right 1.5s ease-in-out forwards;
            }
        }

        .paragraph {
            display: flex;
            flex-direction: column;
            opacity: 1;
            width: 653px;
            height: 110%;
            margin-top: 10px;
            margin-left: 93px;
            transition: opacity 1s ease-in-out;

            .paragraphs {
                opacity: 0;

                &.animation {
                    animation: move-up 1s ease-in-out forwards;
                }
            }
        }
    }

    .footer {
        display: flex;
        width: 100%;
        z-index: 6;
        opacity: 0;
        min-height: 39px;
        margin-top: 100px;
        margin-left: 280px;
        justify-content: center;
        align-items: center;
        color: white;
        font-family: var(--font-secondary);
        font-size: 20px;
        font-style: normal;
        font-weight: 300;
        line-height: 39px;
        letter-spacing: 1.1px;
        transition: opacity 1s ease-in-out;

        p {
            animation: move-up 1.2s ease-in-out forwards;
        }

        a {
            animation: move-up 1.2s ease-in-out forwards;
            text-decoration-line: underline;
            margin-left: 5px;
            color: white;
        }

        a:visited {
            color: white;
        }
    }
}

#about {
    position: absolute;
    width: 100px;
    height: 100px;
    pointer-events: none;
}

@keyframes fade-about {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes move-left {
    from {
        transform: translateX(-30%);
    }

    to {
        transform: translateX(0);
    }
}

@keyframes move-right {
    from {
        transform: translateX(10%);
    }

    to {
        transform: translateX(0);
    }
}

@keyframes move-up {
    from {
        opacity: 0;
        transform: translateY(100px);
    }

    to {
        opacity: 1;
        transform: translateY(0px);
    }
}

@media (min-width: 3000px) and (max-width: 4000px) {
    .about {
        .about-container, .about-container-mobile {
            .about-text {
                margin-left: 430px;

                .about-title {
                    h2 {
                        margin-left: 710px;
                    }

                    h3 {
                        margin-left: 346px;
                        font-size: 392px;
                    }
                }

                .paragraph {
                    width: 1500px;
                    margin-left: 1200px;

                    p {
                        width: 100%;
                    }
                }
            }

            .footer {
                font-size: 50px;
                margin-left: 24.5%;

                a {
                    margin-left: 20px;
                }
            }
        }
    }
}

@media (min-width: 2000px) and (max-width: 3000px) {
    .about {
        .about-container, .about-container-mobile {

            .about-text {
                margin-left: 400px;
                margin-top: -100px;

                .about-title {
                    flex-direction: column;
                    color: var(--color-text-third);

                    h2 {
                        margin-left: -40px;
                    }

                    h3 {
                        font-size: 264px;
                        margin-left: -300px;
                    }
                }

                .paragraph {
                    margin-top: 10px;
                    margin-left: 300px;
                    width: 950px;
                }
            }

            .footer {
                font-size: 28px;
                margin-top: 40px;
                margin-left: 8.2%;
            }
        }
    }
}


@media(max-width: 1600px) {
    .about {
        .about-text {
            margin-left: 20%;

            .about-title {
                h3 {
                    font-size: 164px;
                    margin-left: -150px;
                    
                }

                h2 {
                    margin-left: 6px;
                    margin-top: -30px;
                }
            }
            .paragraph{
                width: 550px;
                margin-left: 180px;
                margin-top: 10px;
            }
        }

        .footer {
            font-size: 16px;
            line-height: 32px;
            margin-left: 14.45%;
        }
    }
}

@media(max-width: 1200px) {
    .about {
        .about-text {
            .paragraph {
                width: 500px;
                margin-left: 160px;
            }
        }
    }
}

@media(max-width: 1440px) {
    .about {
        .about-text {
            margin-top: -2%;
            .about-title {
                h3 {
                    font-size: 140px;
                    margin-left: -22px;
                }

                h2 {
                    margin-top: -30px;
                    margin-left: 120px;
                }
            }

            .paragraph {
                margin-left: 260px;
            }
        }

        .footer {
            margin-top: 50px;
            font-size: 16px;
            margin-left: 19.35%;
        }
    }
}

@media(max-width: 1399px) {
    .about {
        .about-text {
            margin-top: -2%;

            .about-title {
                h3 {
                    font-size: 140px;
                    margin-left: -132px;
                }

                h2 {
                    margin-top: -30px;
                    margin-left: 10px;
                }
            }

            .paragraph {
                .paragraphs {
                    font-size: 14px;
                    line-height: 28px;
                    width: 500px;
                    margin-left: -20px;
                }
            }
        }

        .footer {
            margin-top: 50px;
            font-size: 14px;
            margin-left: 14.5%;
        }
    }
}

@media(max-width: 1280px) {
    .about {
        .about-text {
            margin-top: -2%;

            .about-title {
                margin-left: 34px;
                h3 {
                    margin-left: -170px;
                }

                h2 {
                    margin-top: -30px;
                    margin-left: -20px;
                }
            }

            .paragraph {
                .paragraphs {
                    font-size: 14px;
                }
            }
        }

        .footer {
            margin-top: 50px;
            font-size: 14px;
            margin-left: 14%;
        }
    }
}

@media(max-width:1100px){
    .about{
        .about-text{
            .about-title{
                h3{
                    font-size: 120px;
                }
                h2{
                    margin-top: -20px;
                }
            }
        }
    }
}

@media(max-width: 1000px){
    .about {
        display: flex;
        justify-content: center;
        align-items: left;
        padding-bottom: 200px;
        min-height: 100vh;
        #mobile-animation-about{
            display: flex;
            width: 300px;
            height: 300px;
            position: absolute;
            margin-top: -10%;
            pointer-events: none;
        }
        .about-container, .about-container-mobile{
            margin-top: 25%;
            min-height: 100%;
            height: 100%;
            justify-content: normal;
        }

        .about-text {
            margin-top: -10%;
            justify-content: normal;
            align-items: normal;
            height: auto;
            margin-left: 0;

            .about-title {
                h3{
                    font-size: 80px;
                    margin-left: 5%;
                }
                h2{
                    margin-top: -10px;
                    margin-left: 14%;
                }
            }

            .paragraph {
                margin-top: 10px;
                margin-left: 20%;
                height: max-content;
                .paragraphs{
                    max-width: 550px;
                }
            }
        }

        .footer {
            margin-top: 10px;
            margin-left: 20%;
            font-size: 20px;
        }
    }
}

@media (max-width: 768px) {
    .about {
        display: flex;
        justify-content: center;
        align-items: left;
        padding-bottom: 200px;
        min-height: 100vh;
        #mobile-animation-about{
            display: flex;
            width: 300px;
            height: 300px;
            position: absolute;
            margin-top: -10%;
            pointer-events: none;
        }
        .about-container, .about-container-mobile{
            margin-top: 25%;
            min-height: 100%;
            height: 100%;
            justify-content: normal;
        }

        .about-text {
            margin-top: 0%;
            justify-content: normal;
            align-items: normal;
            height: auto;
            margin-left: 0;

            .about-title {
                h3{
                    margin-left: -8%;
                    font-size: 59px;
                }
                h2{
                    margin-top: -10px;
                    margin-left: 7%;
                }
            }

            .paragraph {
                margin-top: 10px;
                margin-left: 20%;
                height: max-content;
                .paragraphs{
                    max-width: 330px;
                }
            }
        }

        .footer {
            margin-top: 5px;
            margin-left: 67%;
            font-size: 14px;
            align-items: normal;
            justify-content: left;
        }
    }
}

@media(min-height: 1000px) and (max-width: 768px){
    .about{
        margin-top: 10vh;
        margin-left: 5%;
        .about-text {
            .paragraph {
                .paragraphs{
                    max-width: 430px;
                }
            }
        }
        .about-container, .about-container-mobile{
            margin-top: 10%;
        }
    }
}

@media(max-height: 800px) and (max-width: 768px){
    .about{
        .about-container, .about-container-mobile{
            margin-top: 10%;
        }
    }
}

@media(max-height: 700px) and (max-width: 768px) {
    .about{
        .footer{
            font-size: 12px;
        }
    }
}

@media(max-width: 390px) and (max-height: 750px){
    .about{
        .about-container, .about-container-mobile{
            margin-top: 0%; 
        }
    }
}



@media(max-width: 390px){
    .about{
        .about-text{
            .about-title {
                h3{
                    font-size: 50px;
                }
                h2{
                    margin-top: -10px;
                    margin-left: 7%;
                }
            }
            .paragraph{
                .paragraphs{
                    margin-top: -15px;
                    max-width: 290px;
                }
            }
        }
        .footer{
            margin-top: 0px;
            margin-left: 60%;
        }
    }
}


@media(max-height: 450px)  and (max-width: 768px){
    .about{
        .about-text{
            margin-top: 70%;
            .about-title {
                h3{
                    font-size: 40px;
                }
                h2{
                    margin-top: -10px;
                    margin-left: 7%;
                }
            }
            .paragraph{
                .paragraphs{
                    margin-top: -15px;
                    max-width: 290px;
                }
            }
        }
        .footer{
            font-size: 8px;
            line-height: 16px;
        }
    }
}